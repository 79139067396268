import ContentService from "./content/content.service";
import { getAppPath } from "./appPath/appPath.service";
import { getEnvironment } from "./environment/environment.service";
import { getEndpoint } from "./endpoint/endpoint.service";
import { logger } from "./logger/logger.service";
import LogonStatusService from "./logonStatus/logonStatus.service";
import { getSubscriptionStatus } from "./subscriptions/subscriptions.service";
import { getUserEmail } from "./user-email/user-email.service";
export const content = async () => {
  const Content = new ContentService();
  const result = await Content.getContent();
  return result.success ? result.payload.Data : result;
};
export const loggedOn = async () => {
  const LogonStatus = new LogonStatusService();
  return await LogonStatus.getLoggedOnStatus();
};
export { getAppPath as appPath, getEnvironment as environment, getEndpoint as endpoint, logger as log, getSubscriptionStatus as subscribed, getUserEmail };