import axios from "axios";
import { log, endpoint } from "@/services";
const localLogonMock = true;
export default class LogonStatusService {
  constructor() {
    Object.defineProperty(this, "SM_SESSION_URL", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: "/fasglobalnavigation/getSMSession"
    });
  }
  async getLoggedOnStatus() {
    const region = endpoint("REGION");
    if (region === "local") {
      return localLogonMock;
    }
    return this.hasCertCookie() || axios.get(this.SM_SESSION_URL).then(_ref => {
      let {
        data: {
          isLoggedOn
        }
      } = _ref;
      return isLoggedOn;
    }).catch(() => {
      log("Error getting logged on state");
      return false;
    });
  }
  hasCertCookie() {
    const allCookies = document.cookie.split(";");
    const certCookie = decodeURIComponent(allCookies.find(cookie => cookie.includes("cert_type")));
    if (certCookie !== undefined && certCookie !== "undefined") {
      return JSON.parse(certCookie.split("=")[1]).email ? true : false;
    }
    return false;
  }
}