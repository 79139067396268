import axios from "axios";
import { endpoint } from "../";
export default class ContentService {
  constructor() {
    Object.defineProperty(this, "CONTENT_URL", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: "subscriber-content"
    });
    Object.defineProperty(this, "CONTENT_TIMEOUT", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: 5000
    });
    Object.defineProperty(this, "content", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: {}
    });
    Object.defineProperty(this, "getContentURL", {
      enumerable: true,
      configurable: true,
      writable: true,
      value: () => {
        let url = "";
        const domain = window.location.hostname;
        const aemNonProdDomains = ["https://fasaemsitesauth.fase.c1.vanguard.com", "https://fasaemsitesauth.fast.c1.vanguard.com"];
        // If in AEM non prod preview or AEM local development, use AEM direct non prod endpoint
        if (aemNonProdDomains.includes(domain) || domain.includes("coder.opst.c1.vanguard.com")) {
          url = "https://fasaemdisp.fast.c1.vanguard.com/content/fas/digital/globalsubscriber/public.caasv3.json";
          // Else if in prod preview use AEM direct prod endpoint
        } else if (domain.includes("https://fasaemsitesauth.fasp.c1.vanguard.com")) {
          url = "https://fasaemdisp.fasp.c1.vanguard.com/content/fas/digital/globalsubscriber/public.caasv3.json";
          // Else use node server endpoint
        } else {
          url = endpoint("CONTENT_ENDPOINT");
        }
        return url;
      }
    });
  }
  formatContent(content) {
    Object.keys(content.Data).forEach(section => {
      content.Data[section] = content.Data[section].content;
    });
    return content;
  }
  fetchCaasContent() {
    const contentUrl = this.getContentURL();
    if (Object.keys(this.content).length > 0) {
      return Promise.resolve(this.content);
    } else {
      return axios.get(`${contentUrl}`, {
        timeout: this.CONTENT_TIMEOUT
      }).then(result => {
        this.content = this.formatContent(result.data);
        return this.content;
      }).catch(e => {
        return JSON.stringify(e);
      });
    }
  }
  async getContent() {
    try {
      const response = await this.fetchCaasContent();
      return {
        success: true,
        payload: response
      };
    } catch (e) {
      return {
        success: false,
        payload: `CAAS error caught: ${e}`
      };
    }
  }
  injectData(content, data) {
    // https://stackoverflow.com/questions/29182244/convert-a-string-to-a-template-string
    // this is to support adding variables into our content requests
    // i.e: content: { test: 'Test string for ${name}' }
    //   will replace {name} with the passed in arg ({ name: 'myName'})
    const names = data ? Object.keys(data) : [];
    const values = data ? Object.values(data) : [];
    return new Function(...names, `return \`${content.toString()}\``)(...values);
  }
}