import axios from "axios";
import { log, endpoint } from "..";
const ENDPOINT = "/public/ft5/preferences/subscribed";
const localSubscriptionStatus = false;
export const getSubscriptionStatus = email => {
  const isLocal = endpoint("REGION") === "local";
  if (isLocal) {
    return localSubscriptionStatus;
  }
  return axios.post(ENDPOINT, {
    email
  }).then(result => result.data.isSubscribed).catch(() => {
    log("Error getting subscribed state in UI");
    return false;
  });
};