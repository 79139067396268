import axios from "axios";
import { endpoint } from "..";
const USER_DATA_URL = "/web/ecs/xrg-fas-registration-webservice/registration/protected/userDemographicInformation";
const localEmail = "test@test.com";
export const getUserEmail = async () => {
  const isLocal = endpoint("REGION") === "local";
  if (isLocal) {
    return localEmail;
  }
  const registrationEmail = await getFromRegistrationData();
  return registrationEmail || getFromCertLayerCookie();
};
const getFromRegistrationData = async () => {
  return await axios.get(USER_DATA_URL).then(response => {
    return response?.data?.email || "";
  }).catch(() => "");
};
const getFromCertLayerCookie = () => {
  const allCookies = document.cookie.split(";");
  const certCookie = decodeURIComponent(allCookies.find(cookie => cookie.includes("cert_type")));
  return JSON.parse(certCookie.split("=")[1]).email;
};