import { defineStore } from "pinia";
import { ref } from "vue";
import { content as fetchContent } from "@/services";
// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useContentStore = defineStore("content", () => {
  const content = ref(contentObject);
  const getContent = async () => {
    content.value = await fetchContent();
  };
  return {
    content,
    getContent
  };
});
const contentObject = {
  common: {
    announcementsDescription: "",
    certLayerHeader: "",
    models: "",
    privacyStatement: "",
    subscribe: "",
    fundLiteratureDescription: "",
    modelsDescription: "",
    fundLiterature: "",
    advisorsDigestCadence: "",
    registrationLink: "",
    announcementsCadence: "",
    advisorsDigest: "",
    readOur: "",
    fundLiteratureCadence: "",
    modelsCadence: "",
    advisorsDigestDescription: "",
    privacyStatementLink: "",
    announcements: "",
    preferenceCenterLink: "",
    privacyInformation: ""
  },
  loggedOff: {
    signInOptions: "",
    heading: "",
    description: "",
    register: ""
  },
  notSubscribed: {
    heading: "",
    description: ""
  },
  subscribed: {
    manageSubscriptions: "",
    heading: "",
    description: ""
  }
};