import axios from "axios";
export const logger = async message => {
  try {
    axios.post("logger", {
      message: message
    });
  } catch (e) {
    /*eslint-disable */
    console.error(e);
    /*eslint-enable */
  }
};