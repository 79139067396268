import { defineStore } from "pinia";
import { ref } from "vue";
import { loggedOn, subscribed, getUserEmail as fetchUserEmail } from "@/services";
// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore("user", () => {
  const isLoggedOn = ref(false);
  const isSubscribed = ref(false);
  const getLoggedOn = async () => {
    isLoggedOn.value = await loggedOn();
  };
  const getSubscribed = async isLoggedOn => {
    if (isLoggedOn) {
      isSubscribed.value = await subscribed(await fetchUserEmail());
    }
  };
  return {
    isLoggedOn,
    isSubscribed,
    getLoggedOn,
    getSubscribed
  };
});