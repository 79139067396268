import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.to-reversed.js";
import "core-js/modules/es.array.to-sorted.js";
import "core-js/modules/es.array.to-spliced.js";
import "core-js/modules/es.array.with.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.immediate.js";
import "core-js/modules/web.structured-clone.js";
import "core-js/modules/web.url-search-params.size.js";
import "@vanguard/axs-core/src/styles/generator/index.scss";
import "./styles/index.scss";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "@/App.vue";
import { register } from "@vanguard/cert-layer/dist/external-vue/cert-layer.es";
register();
const app = createApp(App);
app.use(createPinia());
app.mount("#email-signup");